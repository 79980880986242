.App {
  text-align: center;
  background: linear-gradient(
    135deg,
    rgb(215, 235, 254),
    rgb(240, 247, 255),
    #f0fff8
  );
  height: 100vh;
  width: 100vw;
  background: linear-gradient(90deg, #dddddd, #f7f6f7, #fdfcfd, #dddddd);
  filter: saturate(170%);
  overscroll-behavior-y: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 1224px) {
  .dimension-logo {
    display: block;
    margin: auto;
    position: fixed;
    top: 20px;
    left: 40px;
    cursor: pointer;
  }

  .social {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100px;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .navbar {
    width: 100vw;
    height: 80px;
    box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.5);
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: linear-gradient(90deg, #dddddd, #f7f6f7, #fdfcfd, #dddddd);
  }

  .landing-languages {
    font-size: 70px;
    font-weight: 600;
  }

  .landing-top {
    text-align: left;
    font-size: 30px;
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 200%;
  }

  .iam {
    padding-top: 20px;
    font-size: 50px;
    font-weight: 500;
  }
  .roles {
    font-size: 20px;
  }

  .mee {
    width: 15vw;
    height: auto;
    border-radius: 50%;
    position: absolute;
    right: 15%;
    top: 10%;
    padding: 5px;
    box-shadow: inset 0 0 10px rgba(47, 45, 45, 0.9);
    filter: saturate(70%);
  }

  .spinner-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000000;
    background: linear-gradient(90deg, #dddddd, #f7f6f7, #fdfcfd, #dddddd);
    display: block;
  }

  .spinner-logo {
    width: 150px;
    height: 150px;
    display: block;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
  }

  .dimension2 {
    animation: pulse 3.6s infinite ease-in-out;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.6);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(0.6);
    }
  }

  .link {
    text-decoration: none;
    color: black;
    transition: 0.3s linear;
  }

  .link:hover {
    transform: scale(1.1);
    transition: 0.3s linear;
  }

  .navbar__link--active {
    color: rgba(0, 0, 0, 0.4);
  }

  .media-top {
    text-align: left;
    font-size: 30px;
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 7%;
    left: 20%;
    line-height: 150%;
  }

  .articles {
    width: fit-content;
    height: 53vh;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 15%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .article-img {
    height: 95%;
    filter: saturate(70%);
    margin: 0 10px;
    box-shadow: 0 0 7px rgba(100, 100, 100, 0.65);
    transition: 0.3s linear;
    cursor: pointer;
  }

  .article-img:hover {
    transform: scale(1.03);
    transition: 0.3s linear;
  }

  .par {
    font-size: 20px;
    width: 70vw;
    line-height: 150%;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 1224px) {
  .dimension-logo {
    display: block;
    margin: auto;
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
  }

  .social {
    position: fixed;
    right: 10px;
    padding-right: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: fit-content;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .navbar {
    width: 100vw;
    height: 80px;
    box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.5);
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: linear-gradient(90deg, #dddddd, #f7f6f7, #fdfcfd, #dddddd);
  }

  .landing-languages {
    font-size: 40px;
    font-weight: 600;
  }

  .landing-top {
    text-align: left;
    font-size: 22px;
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0%, -50%);
    line-height: 200%;
    max-width: 85vw;
  }

  .iam {
    font-size: 25px;
    font-weight: 500;
  }
  .roles {
    max-width: 95%;
    font-size: 14px;
  }

  .mee {
    width: 100px;
    height: auto;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translateX(-50%);
    padding: 5px;
    box-shadow: inset 0 0 10px rgba(47, 45, 45, 0.9);
    filter: saturate(70%);
  }

  .spinner-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000000;
    background: linear-gradient(90deg, #dddddd, #f7f6f7, #fdfcfd, #dddddd);
    display: block;
  }

  .spinner-logo {
    width: 150px;
    height: 150px;
    display: block;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
  }

  .dimension2 {
    animation: pulse 3.6s infinite ease-in-out;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.6);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(0.6);
    }
  }

  .link {
    text-decoration: none;
    color: black;
    transition: 0.3s linear;
  }

  .link:hover {
    transform: scale(1.1);
    transition: 0.3s linear;
  }

  .navbar__link--active {
    color: rgba(0, 0, 0, 0.4);
  }

  .media-top {
    text-align: left;
    font-size: 30px;
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 70px;
    left: 10px;
    line-height: 150%;
  }

  .articles {
    width: 95vw;
    height: fit-content;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: column;
    bottom: 15%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 80px;
  }

  .article-img {
    width: 90vw;
    filter: saturate(70%);
    margin: 10px auto;
    box-shadow: 0 0 7px rgba(100, 100, 100, 0.65);
    transition: 0.3s linear;
    cursor: pointer;
  }

  .article-img:hover {
    transform: scale(1.03);
    transition: 0.3s linear;
  }

  .par {
    font-size: 16px;
    width: 82vw;
    line-height: 150%;
    padding-top: 20px;
    padding-bottom: 100px;
    max-height: 52vh;
    overflow-y: auto;
  }

  .media-outer {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
  }
}
